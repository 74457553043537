@charset "UTF-8";
/*
ユーティリティ系おまとめファイル
*/
/*
██████   █████  ███████ ███████
██   ██ ██   ██ ██      ██
██████  ███████ ███████ █████
██   ██ ██   ██      ██ ██
██████  ██   ██ ███████ ███████
*/
/*
██████  ██████  ███████  █████  ██   ██ ██████   ██████  ██ ███    ██ ████████
██   ██ ██   ██ ██      ██   ██ ██  ██  ██   ██ ██    ██ ██ ████   ██    ██
██████  ██████  █████   ███████ █████   ██████  ██    ██ ██ ██ ██  ██    ██
██   ██ ██   ██ ██      ██   ██ ██  ██  ██      ██    ██ ██ ██  ██ ██    ██
██████  ██   ██ ███████ ██   ██ ██   ██ ██       ██████  ██ ██   ████    ██
*/
/*
 ██████  ██████  ██       ██████  ██████
██      ██    ██ ██      ██    ██ ██   ██
██      ██    ██ ██      ██    ██ ██████
██      ██    ██ ██      ██    ██ ██   ██
 ██████  ██████  ███████  ██████  ██   ██
*/
/*
████████ ███████ ██   ██ ████████
   ██    ██       ██ ██     ██
   ██    █████     ███      ██
   ██    ██       ██ ██     ██
   ██    ███████ ██   ██    ██
*/
#top .slider_catchcopy {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%); }

#top .slider {
  padding: 0;
  line-height: 0;
  margin-bottom: 60px; }
  @media screen and (max-width: 1024px) {
    #top .slider {
      margin-bottom: 30px; } }
  #top .slider li {
    width: 100%;
    height: 720px;
    position: relative;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover; }
    @media screen and (max-width: 568px) {
      #top .slider li {
        height: 500px; } }
  #top .slider_catchcopy {
    font-size: 48px;
    line-height: 1.5;
    text-align: center;
    margin: 0;
    width: 70%; }
    @media screen and (max-width: 568px) {
      #top .slider_catchcopy {
        width: 85%;
        font-size: 30px; } }
  #top .slider .slick-prev, #top .slider .slick-next {
    z-index: 1; }
  #top .slider .slick-prev {
    left: 25px; }
  #top .slider .slick-next {
    right: 25px; }

#top #newslist {
  position: relative; }
  @media screen and (max-width: 1024px) {
    #top #newslist {
      display: flex;
      flex-direction: column; } }
  #top #newslist h2 {
    display: inline-block; }
    @media screen and (max-width: 1024px) {
      #top #newslist h2 {
        order: 1; } }
  #top #newslist .btn {
    position: absolute;
    right: 0;
    top: 55px; }
    @media screen and (max-width: 1024px) {
      #top #newslist .btn {
        position: relative;
        top: initial;
        order: 3; } }
  @media screen and (max-width: 1024px) {
    #top #newslist ul.newslist {
      position: relative;
      order: 2; } }

#life h2 {
  text-align: center; }

#life img {
  max-width: 100%;
  width: 100%; }

#life .has_tree {
  position: relative; }
  #life .has_tree:before {
    content: '';
    background-image: url(/inc/image/img_trees.png);
    position: absolute;
    width: 226px;
    height: 156px;
    bottom: -1.5vw;
    left: -5vw;
    transition: bottom 0.2s ease, left 0.2s ease; }
    @media screen and (max-width: 1280px) {
      #life .has_tree:before {
        left: -4.5vw; } }
    @media screen and (max-width: 768px) {
      #life .has_tree:before {
        bottom: -5vw;
        left: -7.5vw; } }
  #life .has_tree + .col-6_sm-12 {
    position: relative;
    display: flex;
    align-items: center; }
    #life .has_tree + .col-6_sm-12:before {
      content: '';
      position: absolute;
      margin: auto;
      top: -20px;
      left: 0;
      right: 0;
      width: 500px;
      height: 500px;
      background-image: url(/inc/image/bg_decoration_01.png);
      background-position: center center;
      background-size: contain;
      background-repeat: no-repeat;
      z-index: 0; }
      @media screen and (max-width: 1024px) {
        #life .has_tree + .col-6_sm-12:before {
          width: 100%;
          height: 400px;
          background-position: center top; } }

#life .grid-center {
  margin: 150px 0; }
  @media screen and (max-width: 768px) {
    #life .grid-center {
      margin: 80px 0; } }
  #life .grid-center .col-3_md-12 {
    position: relative;
    display: flex;
    align-items: center; }
    #life .grid-center .col-3_md-12:before {
      content: '';
      position: absolute;
      margin: auto;
      top: -160px;
      left: -50%;
      right: 0;
      width: 480px;
      height: 480px;
      background-image: url(/inc/image/bg_decoration_02.png);
      background-position: center center;
      background-size: contain;
      background-repeat: no-repeat;
      z-index: 0; }
      @media screen and (max-width: 1024px) {
        #life .grid-center .col-3_md-12:before {
          top: -40px;
          left: -20px;
          width: 100%;
          height: 400px;
          background-position: center top; } }
  #life .grid-center + .grid > div:first-child {
    position: relative;
    display: flex;
    align-items: center; }
    #life .grid-center + .grid > div:first-child:before {
      content: '';
      position: absolute;
      margin: auto;
      top: -160px;
      left: -60%;
      right: 0;
      width: 480px;
      height: 480px;
      background-image: url(/inc/image/bg_decoration_03.png);
      background-position: center center;
      background-size: contain;
      background-repeat: no-repeat;
      z-index: 0; }
      @media screen and (max-width: 1024px) {
        #life .grid-center + .grid > div:first-child:before {
          top: -40px;
          left: -20px;
          width: 100%;
          height: 400px;
          background-position: center top; } }

#life p {
  padding-left: 1em;
  z-index: 1; }
